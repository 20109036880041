/**
 * Adaptivetheme automates loading of IE conditional stylsheets.
 * See themename_preprocess_html() in template.php in your sub-theme.
 */
#secondary-content-wrapper, #content-wrapper, #header-wrapper .container, #nav-wrapper .container, #leaderboard-wrapper .container, #footer-wrapper .container, #three33-content-wrapper .container, #tertiary-content-wrapper .container, #two50-content-wrapper .container, #bottom-banner-content-wrapper {width: 960px;}
.view-dc-for-kids .view-header .left {width: 250px;}
.view-dc-for-kids .view-header .intro {width: 300px;}
#RRcredit {top: 7px;}
#RRcredit .credit {width: 300px; position: relative; top: 10px;}
#RRcredit .graphic {width: 100px;}
#block-block-2 {width: 100px;} /* weta logo */
#block-menu-menu-mini {display: inline; width: 400px; float: left; /*margin-top: -1em;*/}
#block-menu-menu-mini ul.menu li.last a img {position: relative; top: 1.5em}
#block-block-1 {float: left; display: inline; width: 450px;}
.jcarousel-skin-default .jcarousel-item {width: 220px; height: 220px;}
.jcarousel-skin-default .jcarousel-container-horizontal, .jcarousel-skin-default .jcarousel-clip-horizontal {width: 920px; height: 222px;}
.caroHov span {width: 220px;}
.view-id-flb_featured_graphic {width: 150px;}
.view-id-related_literacy_bags .left {width: 135px;}
.view-id-related_family_literacy_bags .view-content .item-list, .view-id-related_literacy_bags .view-content {width: 300px;}
.view-booklist .views-row .book_cover {width: 100px;}
.view-booklist .views-row .book_blurb {width: 430px;}
.article-type-page .field-name-field-image {width: 38%;}
.article-type-page .field-name-field-intro {width: calc(60% - 1em);}
.jcarousel-skin-default .jcarousel-next-horizontal, .jcarousel-skin-default .jcarousel-prev-horizontal {top: 110px;}
.jcarousel-skin-default .jcarousel-prev-horizontal {left: -30px;}
.jcarousel-skin-default .jcarousel-next-horizontal {left: 920px;}
#three33-content-wrapper .region-three-33-first .block-inner {margin-left: 22px;}
#logo, #slogans {margin: 10px 0;}
#logo {margin: 0 0 0 3em;}
.region-three-25-25-50-second {left: -125px;}
.two-50 .region .one {float: left; width: 125px; margin-right: 1em;}
.two-50 .region .two {float: left; width: 310px;}
#nav-wrapper {box-shadow: 0 1px 10px;}
#block-views-reading-tips-block, #block-views-reading-tips-block-1, #block-views-related-block, #block-views-related-videos-block-2, #block-views-related-videos-block-1, #block-views-dc-for-kids-block-1, #block-views-related-dc-activities-block {box-shadow: 1px 1px 7px #737373;}
#main-content {margin-right: 2em;}
ul#superfish-1 li#menu-698-1 {background: url("../images/3stars_w.png") no-repeat 1em center; padding-left: 75px !important;}
.not-front #block-system-main .block-inner {margin-left: 0;}
#block-print-print-links .block-inner {margin: 0;}
#block-block-20 .block-inner {margin: 0;}
nav#block-block-23 {width: 220px; display: inline; float: left; margin: 0; padding: 0;}
nav#block-superfish-1 {margin-left: 0;}
#block-views-related-block, #block-views-dc-for-kids-block-1, #block-views-related-videos-block-1 {border: solid 1px #737373; padding: 1em;}
.view-id-related_family_literacy_bags .view-footer, .view-id-related_literacy_bags .view-footer {float: none; padding-right: 0;}
.view-booklist .views-row .book_blurb {overflow: auto;}
.bookbox {overflow: visible;}
#block-block-12 ul li {display: inline;}
#block-menu-menu-mini {position: relative; top: 2em;}
#block-menu-menu-mini ul.menu li.last a img {margin-top: -2em}
.caroHov span {top: -3.7em;}
#nav-wrapper {z-index:2; position: relative;}
#content-wrapper {z-index:1; position: relative;}
.two-50 .region-two-50-second .one {width: 280px; margin-left: -1em;}
.two-50 .region-two-50-second .two {width: 130px; margin-left: 2em;}
.nav li a, .nav .menu li a {display: inline;}
#block-block-20 {width: 300px;}
.print_html, .print_mail, .print_pdf {margin: 0 2px 0 0;}
ul#share li {margin-right: 2px;}
.sidebar-first .content-inner {margin-left: 0;}
#views_slideshow_cycle_main_slideshow-block img {max-width: none;}
/*#secondary-content-wrapper {z-index: 15}
#widget_pager_top_slideshow-block {z-index: 999}*/